"use client";

import { useEffect, useState } from "react";
import useTrigger from "./useTrigger";
import { DEVICE } from "consts";

/**
 * A custom hook for tracking and managing screen-related information.
 *
 * @returns {object} An object with screen-related properties.
 */
function useMedia() {
  // Initialize state variables for screen-related properties
  const [top, setTop] = useState(0);
  const [scroll, setScroll] = useState(false);
  const [progress, setProgress] = useState(0);
  const [width, setWidth] = useState(DEVICE.xl);

  // Use a trigger to update 'width' when the window is resized
  useTrigger("resize", () => setWidth(document.body.clientWidth));

  // Use a trigger to track scroll events and update 'scroll' and 'top'
  useTrigger("scroll", () => {
    const pY = window.scrollY;
    if (pY < top) setScroll(true);
    else if (pY > top) setScroll(false);
    setTop(pY);
  });

  // Calculate and update 'progress' based on scroll position (optional)
  useEffect(() => {
    setWidth(document.body.clientWidth);

    const height = document.body.clientHeight;
    const screen = window.innerHeight;

    setProgress(
      Math.round((top / (height - screen)) * window.innerWidth * 1000 || 0) /
        1000
    );
  }, [top]);

  // Return an object with screen-related properties and flags
  return {
    DEVICE,
    laptop: width <= DEVICE.lg,
    tablet: width <= DEVICE.md,
    mobile: width <= DEVICE.xs,
    progress,
    scroll,
    top,
    width,
  };
}

export default useMedia;
