import CONFIG from "data/translate.json";
import DATE from "data/datetime.json";
import EVENT from "./createEvent";
import GOOGLE from "data/googleMaps.json";
import MATCH from "data/locationMatch.json";
import METER from "./verifiedList";
import PLANS from "data/plans.json";
import REGIONS from "data/regions.json";

const APP = {
  alias: "BondUP",
  networkUrl: "https://app.bondup.cl/",
  slogan: "Los 60 los nuevos 40",
  websiteUrl: "https://bondup.cl",
};

const DEVICE = {
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
  xs: 425,
};

const FORM = {
  order: {
    asc: "ASC",
    desc: "DESC",
  },
  view: ["view_headline", "view_stream", "view_comfy"],
};

const GSAP = {
  polygon: {
    b0: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
    b1: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    t0: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
    t1: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
  },
};

const PAGES = [];

const LOGIN = {
  default: {
    email: "",
    password: "",
    rut: "",
    year: new Date().getFullYear() - 50,
    month: 1,
    day: 1,
    gender: "",
    phone: "",
    country: "Chile",
    region: "",
    city: "",
    commune: "",
    firstName: "",
    middleName: "",
    lastName: "",
    surName: "",
    interests: [],
    view: "register",
  },
  required: [
    "email",
    "password",
    "rut",
    "gender",
    "phone",
    "region",
    "commune",
    "firstName",
    "lastName",
    "surName",
  ],
};

const PAYMENT = {
  morose: {
    "Todos los pagos al dia": true,
    "Tiene 1 o más pagos vencidos": false,
    "Tiene pagos pendientes, pero no vencidos": false,
  },
  status: {
    Activa: true,
    Cancelada: false,
    "En período de trial": true,
    Inactivo: false,
  },
};

const ROLES = {
  ambassador: "BUP-AMBASSADOR",
};

const GRID = {
  aside: { xs: 12, lg: 4 },
  container: { xs: 1.5, sm: 2, xl: 4 },
  stack: { xs: 6, sm: 7 },
  main: { xs: 12, lg: 8 },
  grow: { flexGrow: 1 },
  spacing: { xs: 1.5, sm: 2 },
};

export {
  APP,
  CONFIG,
  DATE,
  DEVICE,
  EVENT,
  FORM,
  GOOGLE,
  GRID,
  GSAP,
  LOGIN,
  MATCH,
  METER,
  PAGES,
  PAYMENT,
  PLANS,
  REGIONS,
  ROLES,
};
